<template>
  <topbar />
  <navbar />

  <main
    class="prose-headings:cgn-heading prose-h1:cgn-heading-1 prose-h2:cgn-heading-2 prose-h3:cgn-heading-3 min-h-screen bg-white pb-10 text-gray-800 prose-headings:my-2 prose-headings:text-brand-500 prose-p:font-semibold prose-li:font-semibold"
  >
    <cgn-page-router />
  </main>

  <Footer />
</template>

<script setup lang="ts">
</script>
