<template>
  <!-- top navbar -->
  <header class="relative z-40 font-display text-on-secondary">
    <nav class="border-b border-secondary-300 bg-secondary-500" aria-label="Top">
      <div class="flex h-10 items-center justify-between">
        <div class="flex flex-1 items-center gap-8 px-4">
          <div class="flex gap-1">
            <a v-if="instagramLink" :href="instagramLink" target="_blank" class="block px-2">
              <i-fa:instagram />
            </a>
            <a v-if="facebookLink" :href="facebookLink" target="_blank" class="block px-2">
              <i-fa:facebook />
            </a>
            <a v-if="youtubeLink" :href="youtubeLink" target="_blank" class="block px-2">
              <i-fa:youtube-play />
            </a>
          </div>
          <div class="hidden flex-1 space-x-8 text-sm font-bold uppercase sm:flex">
            <div v-for="link in menus" :key="link.name" class="flex items-center">
              <navbar-dropdown
                v-if="link.identifier == 'dropdown'"
                class="hover:text-secondary-200"
                :menu="link"
              />
              <router-link
                v-else
                :to="link.link"
                class="py-2 hover:text-secondary-200"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </div>
        <!-- Right blocks -->
        <cgn-navbar-cart class="mx-2 mr-4" />
        <cgn-navbar-quick-search class="mx-2 mr-5" />
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { CognitoMenu } from '~cognito/models/Cognito/Menu'

const facebookLink = computed(() => {
  return usePagesStore().currentDomain.facebook_link
})
const instagramLink = computed(() => {
  return usePagesStore().currentDomain.instagram_link
})
const youtubeLink = computed(() => {
  return usePagesStore().currentDomain.youtube_link
})

const menus = ref<CognitoMenu[]>([])

async function loadMenu() {
  menus.value = await new CognitoMenu().getMenu('Top Menu', '16x3', '1920')
}

onMounted(() => {
  loadMenu()
})

onServerPrefetch(async () => {
  await loadMenu()
})
</script>
