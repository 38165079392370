<template>
  <footer aria-labelledby="footer-heading" class="bg-black">
    <h3 id="footer-heading" class="sr-only">
      Footer
    </h3>
    <div class="mx-auto max-w-7xl px-4 pt-10 sm:px-6 lg:px-8">
      <div
        class="grid grid-cols-1 gap-8 md:grid-cols-2"
      >
        <router-link to="/" class="flex justify-center">
          <cgn-organisation-logo class="h-32 w-auto" forcedark />
        </router-link>
        <div class="flex flex-col gap-y-6">
          <!-- Footer links block -->
          <div class="flex flex-col gap-2">
            <div class="grid grid-flow-col grid-rows-3 gap-2 font-bold text-secondary-200">
              <div v-for="link in menus" :key="link.name" class="flex items-center">
                <router-link
                  :to="link.link"
                  class="capitalize hover:text-brand-500"
                >
                  {{ link.name }}
                </router-link>
              </div>
            </div>
            <cgn-button color-brand class="text-sm font-bold uppercase sm:text-lg" url="/events">
              Event tickets
            </cgn-button>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center justify-between gap-2 py-4 md:flex-row">
        <!-- Social media links -->
        <div class="flex gap-2 text-on-secondary">
          <a v-if="instagramLink" :href="instagramLink" target="_blank" class="block px-2">
            <i-fa:instagram />
          </a>
          <a v-if="facebookLink" :href="facebookLink" target="_blank" class="block px-2">
            <i-fa:facebook />
          </a>
          <a v-if="youtubeLink" :href="youtubeLink" target="_blank" class="block px-2">
            <i-fa:youtube-play />
          </a>
        </div>
        <div>
          <cgn-lazy-image image-hash="P5R1" image-aspect="16x3W" image-width="500" class="h-6" />
        </div>
      </div>

      <div class="py-2 text-center">
        <p class="text-sm text-on-secondary">
          &copy; Copyright {{ year }} Hi Octane Motorsport and Photography
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { CognitoMenu } from '~cognito/models/Cognito/Menu'

const facebookLink = computed(() => {
  return usePagesStore().currentDomain.facebook_link
})
const instagramLink = computed(() => {
  return usePagesStore().currentDomain.instagram_link
})
const youtubeLink = computed(() => {
  return usePagesStore().currentDomain.youtube_link
})

const year = ref(new Date().getFullYear())

const menus = ref<CognitoMenu[]>([])
async function loadMenu() {
  menus.value = await new CognitoMenu().getMenu('Footer Menu', '16x3', '1920')
}

onMounted(() => {
  loadMenu()
})

onServerPrefetch(async () => {
  await loadMenu()
})
</script>
